<template>
    <v-card>
        <div class="mx-auto container container--fluid">
            <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
                <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
                <v-btn dark text @click="snackbar = false">Close</v-btn>
            </v-snackbar>

            <v-container>
                <v-toolbar-title>Update PRN No</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-row>
                    <v-col cols="5">
                        <v-file-input placeholder="CSV of Update PRN data" prepend-icon="mdi-file" label="PRN data"
                            show-size v-model="file" :error-messages="!fileStatus ? 'File Required' : ''"></v-file-input>
                    </v-col>

                    <v-btn @click="importCsv" :loading="output_load" class="ma-2">Submit</v-btn>
                    <a href="https://vierp-test.s3.ap-south-1.amazonaws.com/tpo/sample_files/Smple_file_Update_PRN_Tpo.csv">
                        <v-btn :loading="loading3" :disabled="loading3" color="success" class="ma-2 white--text downbtn"
                            @click="loader = 'output_load'"> <v-icon left dark>mdi-cloud-download</v-icon>Download import
                            CSV
                            format </v-btn>
                    </a>

                </v-row>
            </v-container>

            <template>

                <h5 v-if="failList.length > 0" class="mt-4" style="text-align: center; color: red;">Failed Record</h5>
                <v-btn v-if="failList.length > 0" id="export" @click="exportexcel()" color="success" style="float:right;"
                    dark>
                    <v-icon dark>mdi-file-excel</v-icon>
                </v-btn>
                <v-simple-table v-if="failList.length > 0" id="exceltable">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>Sr.No</th>
                                <th>Old PRN</th>
                                <th>New PRN</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in failList" :key="item.name" style="color:red;">
                                <th>{{ item.row }} </th>
                                <th>{{ item.old_prn }} </th>
                                <th>{{ item.new_prn }} </th>
                                <th>{{ item.msg }} </th>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </template>

            <template>
                <h5 v-if="successList.length > 0" class="mt-4" style="text-align: center; color: green;">Success Record
                </h5>
                <v-btn v-if="successList.length > 0" id="export" @click="exportexcel1()" color="success"
                    style="float:right;" dark>
                    <v-icon dark>mdi-file-excel</v-icon>
                </v-btn>
                <v-simple-table v-if="successList.length > 0" id="exceltable1">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>Sr.No</th>
                                <th>Old PRN</th>
                                <th>New PRN</th>
                                <th>Status</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in successList" :key="item.name" style="color:green;">
                                <th>{{ item.row }} </th>
                                <th>{{ item.old_prn }} </th>
                                <th>{{ item.new_prn }} </th>
                                <th>{{ item.msg }} </th>

                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </template>
        </div>
    </v-card>
</template>

<script>
import axios from "axios";
import { table2excel } from "../jquery.table2excel";
export default {
    data: () => ({
        valid: false,
        overlay: false,
        snackbar_msg: "",
        color: "",
        snackbar: false,
        load: false,
        fileStatus: true,
        failList: [],
        successList: [],
        file: null,
        output_load: false,
        loading3: false,
    }),



    methods: {
        exportexcel() {

            $("#exceltable").table2excel({
                name: "Failed PRN Record ",
                exclude_img: false,
                filename: "Failed_Prn_No", //do not include extension
                fileext: ".xlsx", // file extension
                preserveColors: false
            });
        },
        exportexcel1() {

            $("#exceltable1").table2excel({
                name: "Success PRN Record",
                exclude_img: false,
                filename: "Success_Prn_No", //do not include extension
                fileext: ".xlsx", // file extension
                preserveColors: false
            });
        },

        importCsv() {
            this.failList = [];
            this.successList = [];
            if (this.file != null) {
                this.output_load = true;


                if (!(this.file.name.toLowerCase().includes(".csv") || this.file.name.toLowerCase().includes(".CSV"))) {
                    this.showSnackbar("red", "File must be in csv format Only.!");
                    this.output_load = false;

                } else {
                    this.fileuploading();
                }
            } else {
                this.fileStatus = false;
                this.showSnackbar("#b71c1c", "Please select file");
            }
        },

        fileuploading() {
            let formData = new FormData();
            this.output_load = true;
            formData.append("file", this.file);

            axios
                .post("AdminImport/updateprnno", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.output_load = false;
                        this.failList = res.data.failList;
                        this.successList = res.data.successList;
                    }
                    else {
                        this.output_load = false;
                        this.showSnackbar("#b71c1c", "Something went wrong!");
                    }
                })

        },

        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
    },
};
</script>
<style scoped>
.v-text-field {
    padding-top: 0px;
    margin-top: 0px;
}

.v-icon-size {
    font-size: 20px;
}

.edit-avatar {
    transition: 0.3s;
    border: 1px solid #b0bec5;
}

.downbtn {
    float: right;
}

.edit-avatar:hover {
    background-color: #b0bec5;
}

.edit-v-icon {
    padding-left: 6px;
    font-size: 15px;
}

.edit-v-icon:hover {
    color: white;
}
</style>
